import { Routes, Route } from 'react-router-dom';

import ProtectedRoutes from './ProtectedRoutes';

import Login from '../modules/auth/Login';

import Dashboard from '../modules/dashboard/Dashboard';

import SettingsHome from "../modules/settings/home/Home";
import SettingsHomeHeader from "../modules/settings/home/tabs/Header";
import SettingsHomeOffer from "../modules/settings/home/tabs/Offer";
import SettingsHomeAbout from "../modules/settings/home/tabs/About";
import SettingsHomeAdvice from "../modules/settings/home/tabs/Advice";

import SettingsGlobal from "../modules/settings/global/Global";
import SettingsGlobalConfig from "../modules/settings/global/tabs/Config";
import SettingsGlobalDiscover from "../modules/settings/global/tabs/Discover";

import SettingsAbout from "../modules/settings/about/About";
import SettingsAboutHeader from "../modules/settings/about/tabs/Header";
import SettingsAboutAbout from "../modules/settings/about/tabs/About";
import SettingsAboutOther from "../modules/settings/about/tabs/Other";

import SettingsBlogs from "../modules/settings/blogs/Blogs";
import SettingsBlogsHeader from "../modules/settings/blogs/tabs/Header";

import SettingsBooks from "../modules/settings/books/Books";
import SettingsBooksHeader from "../modules/settings/books/tabs/Header";

import Users from "../modules/users/Users";
import Blogs from "../modules/blogs/Blogs";
import Contacts from "../modules/contacts/Contacts";
import Books from "../modules/books/Books";
import Categories from "../modules/categories/Categories";
import Sliders from "../modules/sliders/Sliders";

const Navigations = () => {
  return (
    <Routes>
      <Route path='login' element={<Login />} />
      <Route element={<ProtectedRoutes />}>
        <Route path='' element={<Dashboard />} />
        <Route path="settings/home" element={<SettingsHome />}>
          <Route path='' element={<SettingsHomeHeader />} />
          <Route path='offer' element={<SettingsHomeOffer />} />
          <Route path='about' element={<SettingsHomeAbout />} />
          <Route path='advice' element={<SettingsHomeAdvice />} />
        </Route>
        <Route path="settings/global" element={<SettingsGlobal />}>
          <Route path='' element={<SettingsGlobalConfig />} />
          <Route path='discover' element={<SettingsGlobalDiscover />} />
        </Route>
        <Route path="settings/about" element={<SettingsAbout />}>
          <Route path='' element={<SettingsAboutHeader />} />
          <Route path='about' element={<SettingsAboutAbout />} />
          <Route path='other' element={<SettingsAboutOther />} />
        </Route>
        <Route path="settings/books" element={<SettingsBooks />}>
          <Route path='' element={<SettingsBooksHeader />} />
        </Route>
        <Route path="settings/blogs" element={<SettingsBlogs />}>
          <Route path='' element={<SettingsBlogsHeader />} />
        </Route>

        <Route path="users" element={<Users />} />
        <Route path="blogs" element={<Blogs />} />
        <Route path="categories" element={<Categories />} />
        <Route path="books" element={<Books />} />
        <Route path="contacts" element={<Contacts />} />
        <Route path="sliders" element={<Sliders />} />
      </Route>
    </Routes>
  )
}

export default Navigations